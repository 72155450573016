import * as React from "react";
import { graphql } from "gatsby";
import Feature from "../../components/Feature";
import Layout from "../../components/layout";
import { SEO } from "../../components/seo";
import FeaturesSummary from "../../components/productPage/featuresSummary";

const ForYachtManagementPage = ({ data }) => {
  return (
    <Layout pageTitle="Floatist for Yacht Management">
      <Feature
        title="Revolutionize Yacht Management with Floatist"
        subtitle="Yacht management companies are tasked with the complex coordination of numerous aspects of yacht operations, bridging the communication between owners, captains, and service providers. Floatist is designed to streamline these processes, ensuring efficiency and transparency at every level of operation."
        imageData={data.file.childImageSharp.gatsbyImageData}
        textSide="right"
      />
      <FeaturesSummary />
    </Layout>
  );
};

export default ForYachtManagementPage;

export const Head = () => (
  <SEO description="Discover Floatist, the ultimate yacht management solution
    designed to streamline operations and enhance communication among yacht
    owners, captains, and service providers. Experience unparalleled efficiency
    and transparency in every aspect of yacht management with Floatist's
    innovative platform."
  />
);

export const query = graphql`
  query {
    file(relativePath: { eq: "twoyachts.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
